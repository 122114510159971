@font-face {
	font-family: 'HelveticaNeue';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/helvetica-neue/3626E4_2_0.woff2') format('woff2'),
	url('/fonts/helvetica-neue/3626E4_2_0.woff') format('woff');
}

@font-face {
	font-family: 'HelveticaNeue Fallback';
	font-weight: normal;
	font-style: normal;
	src: local('Arial');
	ascent-override: 76.45%;
	descent-override: 22.20%;
	line-gap-override: 19.73%;
	size-adjust: 101.37%;
}

@font-face {
	font-family: 'HelveticaNeue';
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/helvetica-neue/3626E4_0_0.woff2') format('woff2'),
	     url('/fonts/helvetica-neue/3626E4_0_0.woff') format('woff');
}

@font-face {
	font-family: 'HelveticaNeue Fallback';
	font-weight: 300;
	font-style: normal;
	src: local('Arial');
	ascent-override: 79.89%;
	descent-override: 23.06%;
	line-gap-override: 20.59%;
	size-adjust: 97.14%;
}

@font-face {
	font-family: 'HelveticaNeue';
	font-weight: bold;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/helvetica-neue/3626E4_1_0.woff2') format('woff2'),
		 url('/fonts/helvetica-neue/3626E4_1_0.woff') format('woff');
}

@font-face {
	font-family: 'HelveticaNeue Fallback';
	font-style: normal;
	font-weight: bold;
	src: local('Arial Bold');
	ascent-override: 78.46%;
	descent-override: 21.49%;
	line-gap-override: 19.99%;
	size-adjust: 100.05%;
}
